<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form">
        <div class="row">
            <div class="col-sm-12">
                <div class="web-title">
                    <h2>Change Password</h2>
                </div>
            </div>
            <!--end-main-title of the page-->

            <div class="col-sm-12">
                <div class="default-white-box">
                    <div class="row">
                        <!--end-->
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-12 setting-margin">
                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label>Current Password</label>
                                                <input type="password" class="form-control form-control-solid form-control-lg" v-model="form.current_password" />
                                                    <span class="form-err" v-if="form.errors.has('current_password')" >
                                                    {{form.errors.get('current_password')}}
                                                </span>
                                            </div>
                                        </div>
                                     </div>

                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label>Password</label>
                                                <input type="password" class="form-control form-control-solid form-control-lg" v-model="form.password" />
                                                    <span class="form-err" v-if="form.errors.has('password')" >
                                                    {{form.errors.get('password')}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label>Confirm Password</label>
                                                <input type="password" class="form-control form-control-solid form-control-lg" v-model="form.c_password" />
                                                    <span class="form-err" v-if="form.errors.has('c_password')" >
                                                    {{form.errors.get('c_password')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                           
                           
                            
                            <div class="row">
                                <div class="col-sm-12">
                                    <a @click="updateProfile" class="text-white">
                                        <div class="btn btn-primary font-weight-bold text-uppercase px-9 py-4">
                                            Update Password
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--end-->
                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>
    </div>
    </div>
    </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
export default {
  data() {
    return {
        loading:false,
        error:null,
        form : new Form({
            current_password:null,
            password : null,
            c_password: null,
        }),
    }
  },
  methods: {
      updateProfile: function() {
        this.loading=true;
        this.form.post('/user/change-password').then((response) => {
            if(response.status){
                 this.$router.push({ path: '/dashboard' })
                 this.loading=false;
                 this.$showResponse('success', response.message);
            }
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Change Password" }
    ]);
  },
   created(){   
       
  }
}
</script>